.page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main {
    display: flex;
    background-color: white;
    margin: 1rem 0;
}

.imageImg {
    height: 100vh;
    width: 30rem;
    overflow: hidden;
    object-fit: cover;
}

.info {
    width: 30rem;
    padding: 1rem;
    align-self: center;
}
