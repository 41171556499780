.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    display: flex;
}

.filter {
    flex: 1;
    align-self: flex-start;
    justify-content: start;
    padding: 2rem;
    background-color: white;
    margin: 1rem 0;
    width: 15rem;
}

.filterSection {
    margin: 2rem 0;
}

.content {
    flex: 4;
    display: flex;
    flex-wrap: wrap;
}

.card {
    padding: 0.5rem;
    margin: 1rem;
    background-color: white;
    width: 15rem;
    height: 28rem;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image {
    height: 18rem;
    width: 13rem;
}

.cardImg {
    height: 18rem;
    width: 13rem;
    object-fit: cover;
    overflow: hidden;
}
