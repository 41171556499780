.page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.head {
	height: 70vh;
	background-image: url('./landing.jpg');
	background-position: right;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	border: 2px solid #ccc;
	padding: 0.5rem;
	display: flex;
	margin: 0.5rem 0;
	align-items: center;
	cursor: pointer;
	background-color: white;
}

.shirt {
	flex-direction: row-reverse;
}
