.page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content {
	text-align: center;
	background-color: white;
	padding: 2rem 1rem;
}
