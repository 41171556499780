.page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.main {
	display: flex;
}

.info {
	flex: 2;
	margin: 1rem;
}

.address {
	background-color: white;
	padding: 1rem;
}

.orders {
	margin: 1rem 0;
	background-color: white;
	padding: 1rem;
}

.orderItem {
	margin: 0.5rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.image {
	height: 5rem;
}

.amount {
	margin: 1rem 0;
	flex: 1;
	background-color: white;
	padding: 1rem;
	align-self: flex-start;
	text-align: center;
}
