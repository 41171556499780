.navbar {
	/* box-shadow: 3px 3px 5px #ccc; */
	margin-bottom: 1rem;
}

.logo {
	padding: 0;
	margin: 0;
	width: 5rem;
	height: 5rem;
}
