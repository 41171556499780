.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(200, 200, 200, 0.2);
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
}

.image {
	height: 4rem;
}
