.page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.main {
	background-color: white;
	padding: 1rem;
	text-align: center;
	margin: 1rem 0;
}
