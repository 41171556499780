.page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.password {
	position: relative;
}

.eye {
	position: absolute;
	top: 0;
	right: 1rem;
}
